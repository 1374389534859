import React, { useEffect, useState } from 'react';
import "./style.css";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
//components
import CookiePop from "../../Components/CookiePop"
import { Box, Typography } from '@mui/material';
import NavBar from "../../Components/NavBar"
import Footer from '../../Components/Footer';
import { whiteArrow } from "../../Assets/Data";
import config from "../../config";
import axios from "axios";
import crossIcon from "../../Assets/Images/crossIcon.png"

export default function OrderSuccess({ cookiePop, setCookiePop }) {
    const BACKEND_URL = config.BACKEND_URL;
    const [check, setCheck] = useState(false);
    const [order, setOrder] = useState([]);
    const [orderdetails, setOrderDetails] = useState([]);
    const { orderid } = useParams();

    useEffect( () => {
        if(orderid) {
        axios
        .get(`${BACKEND_URL}/api/V1/get-order/${orderid}`)
        .then((response) => {
            console.log("Response value @@ = ",response.data.result.order[0].orderdetails);
            setOrder(response.data.result.order[0]);
            setOrderDetails(response.data.result.order[0].orderdetails);
        })
        .catch((error) => {
            console.error("Error fetching layout data:", error);
        });
    }
      }, [orderid]);
    
    return (
        <>
            <CookiePop cookiePop={cookiePop} setCookiePop={setCookiePop} />
            <NavBar />
            <Box className="ContactPage">
                <Box className="contactInputSection">
                    <Box className="contactInfo">
                        <Typography className='textInfo1'>Order place successfully </Typography>
                        <Typography sx={{color:"#fffc", marginBottom:"20px !important",fontSize:"16px !important",textAlign:"center"}}>Order Id : {order.code}</Typography>
                        <Typography sx={{color:"#fffc", marginBottom:"20px !important",fontSize:"16px !important",textAlign:"center"}}>Type : {order.payment_type}</Typography>
                        <Typography sx={{color:"#fffc", marginBottom:"20px !important",fontSize:"16px !important",textAlign:"center"}}>Delivery Status : {order.delivery_status}</Typography>
                        <Typography sx={{color:"#fffc", marginBottom:"20px !important",fontSize:"16px !important",textAlign:"center"}}>Grand Total: {order.grand_total}</Typography>
                        <Typography sx={{color:"#fffc", marginBottom:"20px !important",fontSize:"16px !important",textAlign:"center"}}>Grand Total: {order.grand_total}</Typography>
                        <Typography sx={{color:"#fffc", marginBottom:"20px !important",fontSize:"16px !important",textAlign:"center"}}>Order Details: </Typography>
                        {orderdetails.map((el, i) => {
                            return(
                                <Box>
                                    <Typography sx={{color:"#fffc",marginBottom:"20px !important",fontSize:"16px !important",textAlign:"center"}}>Product: {el.prod_name} </Typography>
                                    <Typography sx={{color:"#fffc",marginBottom:"20px !important",fontSize:"16px !important",textAlign:"center"}}>Price: {el.totalPrice} </Typography>
                                    <Typography sx={{color:"#fffc",marginBottom:"20px !important",fontSize:"16px !important",textAlign:"center"}}>Quantity: {el.quantity} </Typography>
                                </Box>
                            )
                        })}
                    </Box>                    
                </Box>
                {/* mail section */}
                {/* <Box className="mailSection">
                    <Typography className='ambHeader'>Join our <span className='TimesNowText'>community</span></Typography>
                    <Typography className='summery14'>Sign up and stay up to date on product launches and pre order. By entering your email address you agree to our Terms and Conditions and Privacy and Cookie Policy. </Typography>

                    <Box className="mailInputBox">
                        <input type="email" name="email" placeholder='Your email*' />
                        <Box className="submitBtn">
                        {whiteArrow}
                        </Box>
                    </Box>

                    <Box className="MailCheckBox">
                        <Box className={check ? "check checkActive" : "check"} onClick={() => setCheck(!check)}></Box>
                        <Typography>Please click here to accept our</Typography>
                        <Box className="termsbox">
                        <Typography>Terms and Conditions</Typography>
                        <Box></Box>
                        </Box>
                    </Box>
                </Box> */}
            </Box>
            <Footer />
        </>
    )
}