// config.js
const config = {
  // FRONTEND_URL: "http://localhost:3000",
  // BACKEND_URL: "http://localhost:8000",

  FRONTEND_URL: "https://n25.neuronsit.in/",
  BACKEND_URL: "https://adminn25.neuronsit.in/",

  // FRONTEND_URL: "https://usan25.neuronsit.in/",
  // BACKEND_URL: "https://adminn25.neuronsit.in/",

  // FRONTEND_URL: "https://australian25.neuronsit.in/",
  // BACKEND_URL: "https://adminn25.neuronsit.in/",

  STRIPE_PUBLISHABLE_KEY:
    "pk_test_51PVwMMInZXiKhwLnISkUMdieoYbmklN9kEJbUm284Dx0xYMSmVBsHvS0PjrubIGJw62S7n0eXJxIWn0i1MvN9v5k00fBcQWAFL",
  STRIPE_SECRET_KEY:
    "sk_test_51PVwMMInZXiKhwLnBpAqarOkcMKIydYVt4Pd7cIcnfvBz3s7JRJEndCWr92UqR25CFyo1JIHQDz93iweA5XCfwQU00eLSE3xn8",
};

export default config;
