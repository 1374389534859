
import React, { useState } from "react";
import './modal.css';
import caviar from "../../../src/Assets/Images/N25Caviar.jpeg";

const Modal = ({ isOpen, onClose }) => {
  
  const [selectedCountry, setSelectedCountry] = useState("uk");

  if (!isOpen) return null;

  const handleSubmit = () => {
    let url = "";
    switch (selectedCountry) {
      case "uk":
        url = "https://n25.neuronsit.in/";
        break;
      case "usa":
        url = "https://usan25.neuronsit.in/";
        break;
      case "australia":
        url = "https://australian25.neuronsit.in/";
        break;
      default:
        return; // Do nothing if no valid selection
    }

    if (url) {
      window.open(url, "_blank"); // Open in new tab
    }
  };

  return (
    <div className="model-overlay" style={{ opacity: 1, visibility: 'inherit' }}>
      <div className="modal-content">
        <div className="modal-container-image">
          <img
            alt="Subscribe to our newsletter and stay up to date with N25 Caviar"
            loading="lazy"
            decoding="async"
            src={caviar}
            className="modal-image"
          />
        </div>

        <div className="modal-body">
          <button className="close-btn" onClick={onClose}>
            <p className="close-icon">X</p>
            <span className="top-border"></span>
            <span className="right-border"></span>
            <span className="bottom-border"></span>
            <span className="left-border"></span>
          </button>

          <h5 className="modal-title">If you wan to change country, then choose here.</h5>

          <form className="newsletter-form" defaultValue="option2" noValidate autoComplete="off" style={{ border: 'none' }} onSubmit={(e) => e.preventDefault()}>
            <select name="country" defaultValue="" className="select-menu" value={selectedCountry}
        onChange={(e) => setSelectedCountry(e.target.value)}>
          
              <option value="uk">UK</option>
              <option value="usa">USA</option>
              <option value="australia">AUSTRALIA</option>
            </select>

            <span className="submit-btn" onClick={handleSubmit}>Submit</span>
          </form>

        </div>
      </div>
    </div>

  );
};

export default Modal;
