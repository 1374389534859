import React, { useState, useEffect } from "react";
import "./style.css";
import { useNavigate, Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import config from "../../config";
import { TOAST_CONFIG } from "../../lib/constants";
//images
import { Clogo, UserIcon } from "../../Assets/Data";
import crossIcon from "../../Assets/Images/crossIcon.png";
import productImg from "../../Assets/Images/n25-oscietra-caviar-main-img.png";
import { getCountryCode } from "../../services/countryService";
import Modal from "../PopBox/Modal";
//component
export default function NavBar({ activeNav, setActiveNav, totalCartItems }) {
    const BACKEND_URL = config.BACKEND_URL;
    const navigate = useNavigate();
    const [navMenu, setNavMenu] = useState(false);
    const [cartPop, setCartPop] = useState(false);
    const [cartItem, setCartItem] = useState([]);
    const [totalAmount, setTotalAmount] = useState();
    const [totalCartItem, setTotalCartItem] = useState(0);
    const userSession = localStorage.getItem("User");
    const productList = [1, 2];
    const [guestCartItem, setGuestCartItem] = useState([]);
    const [allCategories, setAllCategories] = useState([]);
    const [menuLoading, setMenuLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    // console.log("Total Cart Items @@ =",totalCartItems);

    useEffect(() => {
        const countryCode = getCountryCode();
        axios.get(`${BACKEND_URL}/api/V1/shop-now/content`, { params: { country: countryCode } })
            .then((response) => {
                setMenuLoading(true);
                setAllCategories(response.data.result.catgories);
            })
            .catch((error) => {
                console.error("Error fetching layout data:", error);
            });
    }, []);
    const [checkLogin, setCheckLogin] = useState(false);

    const handleNavClose = (e) => {
        if (e.target.id === "grayBox") {
            setNavMenu(false);
            setCartPop(false);
        }
    };

    const handleShop = () => {
        console.log("Test Test");
        setShowModal(true);
      }
    
      const handleModalClose = () => {
        setShowModal(false);
     }

    const getCartItem = (postData) => {
        axios
            .post(`${BACKEND_URL}/api/V1/user-cart-data`, postData)
            .then((response) => {
                if (response.data.status) {
                    const cartItems = response.data.result.cartitem;
                    // console.log("Cart Length 2", cartItems.length);
                    setGuestCartItem(cartItems);
                    setTotalCartItem(cartItems.length);
                    setCartItem(response.data.result.cartitem);
                    setTotalAmount(response.data.result.total_amount);
                    const cartTotalItemObject = {
                        total_item: response.data.result.cartitem.length
                    }
                    sessionStorage.setItem("cart_total_item", JSON.stringify(cartTotalItemObject));
                } else {
                    setTotalCartItem(0);
                    setTotalAmount(0);
                    setGuestCartItem([]);
                    console.log("No cart items for logged-in user.");
                }
            })
            .catch((error) => {
                console.error("Error fetching cart items:", error);
            });
    };

    useEffect(() => {
        if (userSession !== undefined && userSession !== null) {
            setCheckLogin(true);
        } else {
            setCheckLogin(false);
        }
    }, [userSession]);


    useEffect(() => {
        if (navMenu || cartPop) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "unset";
        }

        if (userSession) {
            const User = JSON.parse(userSession);
            const postData = {
                user_id: User.id,
            };
            console.log("User Login @@ =", postData);
            getCartItem(postData); // Ensure this fetches cart items for logged-in users
        } else {
            const guestCart = JSON.parse(sessionStorage.getItem("guestCart") || "null");
            if (guestCart && Array.isArray(guestCart) && guestCart.length > 0) {
                const totalPrice = calculateTotalPrice(guestCart);
                setTotalAmount(totalPrice);
                setGuestCartItem(guestCart);
                setTotalCartItem(guestCart.length);
            } else {
                setTotalAmount(0);
                setGuestCartItem([]);
                setTotalCartItem(0);
            }
            // console.log("Cart Length 1", guestCart);
        }

        localStorage.removeItem("cart_total_item");
        return () => {
            document.body.style.overflow = "unset";
        };
    }, [navMenu, cartPop, guestCartItem, userSession]);

    // const getCartItem = (postData) => {
    //     axios
    //     .post(`${BACKEND_URL}/api/V1/user-cart-data`, postData)
    //     .then((response) => {
    //         setCartItem(response.data.result.cartitem);
    //         setTotalAmount(response.data.result.total_amount);
    //         setTotalCartItem(response.data.result.cartitem.length);
    //         console.log("Cart Lenght 2", response.data.result.cartitem.length);
    //         const cartTotalItemObject  = {
    //             total_item : response.data.result.cartitem.length
    //         }
    //         sessionStorage.setItem("cart_total_item", JSON.stringify(cartTotalItemObject));
    //     })
    //     .catch((error) => {
    //         console.error("Error fetching layout data:", error);
    //     });
    // };

    const removeCartItem = (cartid) => {
        if (userSession) {
            const User2 = JSON.parse(userSession);
            const postData = {
                cart_id: cartid,
            };

            axios
                .post(`${BACKEND_URL}/api/V1/remove-cart-item`, postData)
                .then((response) => {
                    const postData = {
                        user_id: User2.id,
                    };
                    getCartItem(postData);
                    toast(response.data.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                })
                .catch((error) => {
                    console.error("Error fetching layout data:", error);
                });
        }
    };

    const handleUpCount = (cartid, qty) => {
        if (userSession) {
            const User6 = JSON.parse(userSession);
            const countVal = qty + 1;
            const postData = {
                cart_id: cartid,
                qty: countVal,
            };
            axios
                .post(`${BACKEND_URL}/api/V1/update-qty`, postData)
                .then((response) => {
                    if (response.data.status) {
                        const userPostData = {
                            user_id: User6.id,
                        };
                        getCartItem(userPostData);
                    } else {
                        toast.error(response.data.message, TOAST_CONFIG);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching layout data:", error);
                });
        }
    };

    const handleDownCount = (cartid, qty) => {
        qty = qty;
        if (qty === 1) {
            return qty;
        } else {
            qty = qty - 1;
        }

        const postData = {
            cart_id: cartid,
            qty: qty,
        };
        changeCartItemQty(postData);
    };

    const changeCartItemQty = (postData) => {
        if (userSession) {
            const User7 = JSON.parse(userSession);
            axios
                .post(`${BACKEND_URL}/api/V1/update-qty`, postData)
                .then((response) => {
                    const userPostData = {
                        user_id: User7.id,
                    };
                    getCartItem(userPostData);
                })
                .catch((error) => {
                    console.error("Error fetching layout data:", error);
                });
        }
    };

    const plusProductQty = (cartid, qty) => {
        console.log("cart ID : ", cartid);
        console.log("Qty : ", qty);
    };

    const redirectCheckout = () => {
        navigate("/payment");
    }
    const redirectGuestCheckout = () => {
        navigate("/guest-payment");
    }
    const redirectShopping = () => {
        setCartPop(false);
        navigate("/all-products");
    }
    // =========================================== GUEST USER =================================================
    const incrementQty = (variationId, currentQty, price) => {
        var qty = currentQty + 1;
        var productTotalPrice = price * qty;
        console.log('incrementQty',productTotalPrice);
        updateItemQuantity(variationId, qty, productTotalPrice);
        const guestCart = JSON.parse(sessionStorage.getItem("guestCart"))
        const totalPrice = calculateTotalPrice(guestCart);
        setTotalAmount(totalPrice);
    };

    const decrementQty = (variationId, currentQty, price) => {
        let qty = currentQty;
        if (qty === 1) {
            var productTotalPrice = price;
            updateItemQuantity(variationId, qty, productTotalPrice);
            const guestCart = JSON.parse(sessionStorage.getItem("guestCart"))
            const totalPrice = calculateTotalPrice(guestCart);
            setTotalAmount(totalPrice);
            return qty;
        } else {
            qty = qty - 1;
        }
        var productTotalPrice = price * qty;
        updateItemQuantity(variationId, qty, productTotalPrice);
        const guestCart = JSON.parse(sessionStorage.getItem("guestCart"))
        const totalPrice = calculateTotalPrice(guestCart);
        setTotalAmount(totalPrice);
    };
    const updateItemQuantity = (variationId, newQty, productTotalPrice) => {
        setGuestCartItem((prevItems) => {
            const newCart = prevItems.map((item) =>
                item.variation.id === variationId
                    ? { ...item, qty: newQty, variation: { ...item.variation, price: productTotalPrice } }
                    : item
            )
            sessionStorage.setItem("guestCart", JSON.stringify(newCart));
            return newCart;
        });
    };
    const removeProduct = (variationId) => {
        setGuestCartItem((prevItems) => {
            const newCart = prevItems.filter((item) => item.variation.id !== variationId);
            sessionStorage.setItem("guestCart", JSON.stringify(newCart));
            return newCart;
        });
    };
    const calculateTotalPrice = (items) => {
        if (items !== null) {
            return items.reduce((total, item) => {
                // return total + parseFloat(item.variation.price); old code
                // console.log('Total : ', item.id, (total + parseFloat(item.price * item.qty)));
                return total + parseFloat(item.price * item.qty);
            }, 0);
        } else {
            return 0;
        }
    };
    // =========================================== GUEST USER =================================================
    return (
        <>
            <Box className="NavBar">
            <Modal isOpen={showModal} onClose={handleModalClose}/>
                <Box className="navInnerBox navInnerBox1">
                    <Box className="navLineMenuBox" onClick={() => setNavMenu(!navMenu)}>
                        <Box
                            className={
                                navMenu
                                    ? "lineMenuBox lmb1 lineMenuBoxMActive"
                                    : "lineMenuBox lmb2"
                            }
                        >
                            <Box className="nml l1"></Box>
                            <Box className="nml l2"></Box>
                        </Box>
                        <Box
                            className={
                                navMenu
                                    ? "lineMenuBox lmb2 lineMenuBoxMActive"
                                    : "lineMenuBox lmb2"
                            }
                        >
                            <Box className="nml l1 l1v2"></Box>
                            <Box className="nml l2 l1v2"></Box>
                        </Box>
                        <Box className={navMenu ? "NavSLine NavSLineActive" : "NavSLine"}>
                            <Box className="l3"></Box>
                        </Box>
                    </Box>
                    {Clogo}
                </Box>
                <Box className="navInnerBox">
                    <Box
                        className="TextFlipBox DshopText"
                        onClick={() => navigate("/all-products")}
                    >
                        <Typography className="navItemTExt text1 shop-now">
                            Shop Now
                        </Typography>
                        <Typography className="navItemTExt text2 shop-now">
                            Shop Now
                        </Typography>
                    </Box>
                    <Box className="cartWUBox">
                        <Link to={"/profile"}>{UserIcon}</Link>
                        <Typography onClick={() => setCartPop(true)} className="navCartText">
                            Cart {totalCartItem}
                        </Typography>
                    </Box>
                </Box>
                <Box
                    onClick={handleNavClose}
                    id="grayBox"
                    className="grayBox"
                    sx={{ display: navMenu || cartPop ? "block" : "none" }}
                ></Box>

                {/* Nav menu */}
                <Box className={navMenu ? "navMenu navMenuActive" : "navMenu"}>
                    <Box className="navMenuMainSection">
                        <Box className="fMidInBox nf2t">
                            <Box className="TextFlipBox" onClick={() => navigate("/n25")}>
                                <Typography className="navItemTExt text1">N25</Typography>
                                <Typography className="navItemTExt text2">N25</Typography>
                            </Box>
                            <Box
                                className="TextFlipBox"
                                onClick={() => navigate("/our-Stories")}
                            >
                                <Typography className="navItemTExt text1">
                                    Our Stories
                                </Typography>
                                <Typography className="navItemTExt text2">
                                    Our Stories
                                </Typography>
                            </Box>
                            <Box className="TextFlipBox" onClick={() => navigate("/people")}>
                                <Typography className="navItemTExt text1">
                                    Friends of the Brand
                                </Typography>
                                <Typography className="navItemTExt text2">
                                    Friends of the Brand
                                </Typography>
                            </Box>
                        </Box>
                        <Box className="fMidInBox nvMTextB nf2t itText">
                            {allCategories?.map((item, index) => (
                                <Box key={index}
                                    className="TextFlipBox"
                                    onClick={() => navigate(`${item.link}${item.id}`)}
                                >
                                    <Typography className="navItemTExt navtext2 text1">{item.menu_name}</Typography>
                                    <Typography className="navItemTExt navtext2 text2">{item.menu_name}</Typography>
                                </Box>
                            ))}
                            {/* <Box
                                className="TextFlipBox"
                                onClick={() => navigate("/our-accessories")}
                            >
                                <Typography className="navItemTExt text1">
                                    Accessories / Delicacies
                                </Typography>
                                <Typography className="navItemTExt text2">
                                    Accessories / Delicacies
                                </Typography>
                            </Box>
                            <Box className="TextFlipBox">
                                <Typography className="navItemTExt text1">Wines</Typography>
                                <Typography className="navItemTExt text2">Wines</Typography>
                            </Box> */}
                        </Box>
                        <Box className="fMidInBox NavLastMS">
                            <Box className="TextFlipBox" onClick={() => navigate("/the-caviar")}>
                                <Typography className="navItemTExt navtext2 text1">
                                    The Caviar
                                </Typography>
                                <Typography className="navItemTExt navtext2 text2">
                                    The Caviar
                                </Typography>
                            </Box>

                            <Box
                                className="TextFlipBox"
                                onClick={() => navigate("/n25-global")}
                            >
                                <Typography className="navItemTExt  text1">
                                    N25 Global
                                </Typography>
                                <Typography className="navItemTExt text2">
                                    N25 Global
                                </Typography>
                            </Box>

                            <Box className="TextFlipBox" onClick={() => navigate("/contact")}>
                                <Typography className="navItemTExt  text1">Contacts</Typography>
                                <Typography className="navItemTExt text2">Contacts</Typography>
                            </Box>
                            <Box className="TextFlipBox" onClick={() => navigate("/account?dr=1")}>
                                <Typography className="navItemTExt text1">My account</Typography>
                                <Typography className="navItemTExt text2">My account</Typography>
                            </Box>
                            <Box className="TextFlipBox" onClick={() => navigate("/account?dr=0")}>
                                <Typography className="navItemTExt text1">Order</Typography>
                                <Typography className="navItemTExt text2">Order</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="fLastBox">
                        <Box className="fLastInBox">
                            <Typography>©2024 N25.</Typography>
                        </Box>
                        <Box className="fLastInBox">
                            <Typography>All rights reserved.</Typography>
                        </Box>
                        <Box className="fLastInBox paddingNL">
                            <Box className="TextFlipBox" onClick={() => handleShop()}>
                                <Typography className="navItemTExt text1">
                                    Go to shop
                                </Typography>
                                <Typography className="navItemTExt text2">
                                    Go to shop
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                {/* -----------------cart--------------------- */}
                <Box className={cartPop ? "cartBox cartBoxActive" : "cartBox"}>
                    <Box className="cartInnerBox">
                        <Box className="cartNav">
                            <Box className="cartCrossBtn" onClick={() => setCartPop(false)}>
                                <img src={crossIcon} />
                            </Box>
                            <Typography>Cart {totalCartItem}</Typography>
                        </Box>
                        {totalCartItem > 0 ? (
                            <>
                                <Box className="cartListBox">
                                    {cartItem.length > 0 ? (
                                        <>
                                            {cartItem.map((item) => {
                                                return (
                                                    <Box className="productItem">
                                                        <Box className="cProductImg">
                                                            <img src={`${BACKEND_URL}${item.image}`} />
                                                        </Box>
                                                        <Box className="productItemTextBox">
                                                            <Typography className="cartProductTitle">
                                                                {item.prod_name}
                                                            </Typography>
                                                            <Typography className="cartProductTitle">
                                                                {item.variation_name}
                                                            </Typography>
                                                            <Box className="productBtnBox">
                                                                <Typography className="cartProductTitle">
                                                                    £ {item.price}
                                                                </Typography>
                                                                <Box className="inputNumber">
                                                                    <button type="button" onClick={() => handleDownCount(item.id, item.qty)} > - </button>
                                                                    <input type="text" name="" id="" value={item.qty} />
                                                                    <button type="button" onClick={() => handleUpCount(item.id, item.qty)}> + </button>
                                                                </Box>
                                                                <Box className="cartCrossBtn" onClick={() => removeCartItem(item.id)} >
                                                                    <img src={crossIcon} />
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                );
                                            })}
                                        </>
                                    ) : (
                                        <>
                                            {guestCartItem.map((item) => {
                                                return (
                                                    <Box className="productItem">
                                                        <Box className="cProductImg">
                                                            <img src={`${BACKEND_URL}${item.image}`} />
                                                        </Box>
                                                        <Box className="productItemTextBox">
                                                            <Typography className="cartProductTitle">
                                                                {item.name}
                                                            </Typography>
                                                            <Typography className="cartProductTitle">
                                                                {item.variation.name}
                                                            </Typography>
                                                            <Box className="productBtnBox">
                                                                <Typography className="cartProductTitle">
                                                                    £ {item.variation.price }
                                                                </Typography>
                                                                <Box className="inputNumber">
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => decrementQty(item.variation.id, item.qty, item.price)}
                                                                    >
                                                                        -
                                                                    </button>
                                                                    <input type="text" name="" id="" value={item.qty} />
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => incrementQty(item.variation.id, item.qty, item.price)}
                                                                    >
                                                                        +
                                                                    </button>
                                                                </Box>
                                                                <Box
                                                                    className="cartCrossBtn"
                                                                    onClick={() => removeProduct(item.variation.id)}
                                                                >
                                                                    <img src={crossIcon} />
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                )
                                            })}
                                        </>
                                    )}

                                </Box>
                                <Box sx={{ padding: "16px" }} className="cartTotalPBox">
                                    <Typography className="totalLavel">Cart total</Typography>
                                    <Typography className="totalValue">£ {totalAmount}</Typography>
                                </Box>
                                <Box sx={{ padding: "16px" }} className="cartTotalPBox ntb">
                                    <Typography className="anountText">
                                        You have reached the amount for free shipping.
                                    </Typography>
                                </Box>
                                <Box className="cardFBtnBox">
                                    <span className="discoverBtn" onClick={() => setCartPop(false)}>
                                        <Typography>Continue Shopping</Typography>
                                        <span className="dbsLine dbTops"></span>
                                        <span className="dbsLine dbRighs"></span>
                                        <span className="dbsLine dbBottoms"></span>
                                        <span className="dbsLine dbLefts"></span>
                                    </span>
                                    <p className="orText">Or</p>
                                    <span className="discoverBtn cfBlackBtn" onClick={() => { checkLogin ? (redirectCheckout()) : (redirectGuestCheckout()) }}>
                                        <Typography>Proceed to checkout</Typography>
                                        <span className="dbsLine dbTops"></span>
                                        <span className="dbsLine dbRighs"></span>
                                        <span className="dbsLine dbBottoms"></span>
                                        <span className="dbsLine dbLefts"></span>
                                    </span>
                                </Box>
                            </>
                        ) : (
                            <Box className="emptyCartMessage">
                                <Typography sx={{ color: "#000", padding: "40px" }}>Your cart is empty. Start shopping now!</Typography>
                                <Box className="cardFBtnBox">
                                    <span className="discoverBtn cfBlackBtn" onClick={() => redirectShopping()}>
                                        <Typography>Continue Shopping</Typography>
                                        <span className="dbsLine dbTops"></span>
                                        <span className="dbsLine dbRighs"></span>
                                        <span className="dbsLine dbBottoms"></span>
                                        <span className="dbsLine dbLefts"></span>
                                    </span>
                                </Box>
                            </Box>
                        )}

                    </Box>
                </Box>
            </Box>
        </>
    );
}
