import React, { useEffect, useState } from 'react';
import "./style.css";

//components
import { Box, Typography } from '@mui/material';
import NavBar from "../../Components/NavBar"
import Footer from '../../Components/Footer';
import { whiteArrow } from "../../Assets/Data";
import VideoWithPlayButton from "../../Components/VideoPlayer"
import CookiePop from "../../Components/CookiePop"

//images
import rafaImg1 from "../../Assets/Images/rafaImg1.jpeg"
import rightImg from "../../Assets/Images/right.png";
import closeImg from "../../Assets/Images/close.png";
import slideImg1 from "../../Assets/Images/rafaSliderImg1.jpeg"
import slideImg2 from "../../Assets/Images/rafaSliderImg2.jpeg"
import slideImg3 from "../../Assets/Images/rafaSliderImg3.jpeg"
import caviBolImg from '../../Assets/Images/caviIBolImg.jpg'
import redFlowar from "../../Assets/Images/redFlowar.png"
import chefSection from "../../Assets/Images/chefSection.jpg";
import DownArrowIcon from "../../Assets/Images/chevron-down.png";
import config from "../../config";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import EmailCommunity from "../../Components/EmailCommunity";
import { getCountryCode } from '../../services/countryService';

export default function RefaCagali({ cookiePop, setCookiePop }) {
    const BACKEND_URL = config.BACKEND_URL;
    const navigate = useNavigate();
    const [check, setCheck] = useState(false);
    const [firstRow, setFirstRow] = useState(false);
    const [secondRow, setSecondRow] = useState(false);
    const [isRotated, setIsRotated] = useState(false);
    const [isRotated2, setIsRotated2] = useState(false);
    const [firstSection, setFirstSection] = useState([]);
    const [secondSection, setSecondSection] = useState([]);
    const [thirdSection, setThirdSection] = useState([]);
    const [fourthSection, setFourthSection] = useState([]);
    const [fifthSection, setFifthSection] = useState([]);
    const [futures, setFutures] = useState([]);
    const toggleRotation = () => {
        setIsRotated(!isRotated);
    };
    const toggleRotation2 = () => {
        setIsRotated2(!isRotated2);
    };

    useEffect(() => {
        const countryCode = getCountryCode();
        axios
        .get(
            `${BACKEND_URL}/api/V1/loyalty-future/content`,{
            params: { country: countryCode }
        })
        .then((response) => {
            setFirstSection(response.data.result?.content?.first_section);
            setSecondSection(response.data.result?.content?.second_section);
            setThirdSection(response.data.result?.content?.third_section);
            setFourthSection(response.data.result?.content?.fourth_section);
            setFifthSection(response.data.result?.content?.fifth_section);
            setFutures(response.data.result?.loyalty_futures);
        })
        .catch((error) => {
            console.error("Error fetching layout data:", error);
        });
    }, []);

    return (
        <>
            <CookiePop cookiePop={cookiePop} setCookiePop={setCookiePop} />
            <NavBar />
            <Box className="rafaPage">
                <Box className="rafaImgBox1">
                    <Box className="ovCInneerBox" >
                        <Box className="ovTextBox" style={{ background : "transparent", width : "50%" }}>
                            <Typography sx={{fontSize:"38px !important"}} className="ovTbHeader">{firstSection?.fs_title}</Typography>
                            <Typography className="summery14">{firstSection?.fs_subtitle}</Typography>
                        </Box>
                    </Box>
                    <img src={`${BACKEND_URL}${firstSection?.fs_backgroun_image}`} />
                </Box>
                <Box className="RafatextBox">
                    <Typography style={{textAlign:"center",fontWeight:"500",fontSize:"32px"}} className='rafaTBHText'>{secondSection?.ss_title}</Typography>
                    <Typography className='rafaTBNText'>{secondSection?.ss_content}</Typography>
                </Box>
                <Box className="howWorkBox">
                    <Typography sx={{textAlign:"center",fontSize:"32px", fontWeight:"500"}}>{thirdSection?.ts_main_title}</Typography>
                    <Box sx={{display:"flex",flexDirection:"row",justifyContent:"space-around",marginTop:"20px",gap:"16px",padding:"12px 16px"}}>
                        <Box className="">
                            <Typography style={{display: "flex", justifyContent: "center", alignItems: "center",paddingBottom:"13px"}}><svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M48.1667 24C48.1667 36.9787 37.6454 47.5 24.6667 47.5C11.688 47.5 1.16669 36.9787 1.16669 24C1.16669 11.0213 11.688 0.5 24.6667 0.5C37.6454 0.5 48.1667 11.0213 48.1667 24Z" stroke="white"></path>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M34.5002 34H14.5002V19H20.5002H28.5002H34.5002V34ZM21.5002 17C21.5002 15.346 22.8462 14 24.5002 14C26.1542 14 27.5002 15.346 27.5002 17V18H21.5002V17ZM28.5002 18V17C28.5002 14.791 26.7092 13 24.5002 13C22.2912 13 20.5002 14.791 20.5002 17V18H13.5002V35H35.5002V18H28.5002Z" fill="white"></path>
                            </svg></Typography>
                        <Typography sx={{textAlign:"center"}}><strong>{thirdSection?.ts_title_1}</strong></Typography>
                        <Typography sx={{marginTop:"16px"}}>{thirdSection?.ts_content_1}</Typography>
                        </Box>
                        <Box className="">
                            <Typography style={{display: "flex", justifyContent: "center", alignItems: "center",paddingBottom:"10px"}}><svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="24" cy="24" r="23.5" stroke="white"></circle>
                                <path d="M31.2727 24V13.0909H14.9091V34.9091C20.6258 34.9091 32.6043 34.9091 32.3636 34.9091" stroke="white"></path>
                                <path d="M28.5754 28.0665L31.6364 30.4464L38.1818 24.3894" stroke="white"></path>
                                <path d="M18.1818 18.5455H26.9091" stroke="white"></path>
                                <path d="M18.1818 22.9092H26.9091" stroke="white"></path>
                                <path d="M18.1818 27.2727L23.6363 27.2727" stroke="white"></path>
                            </svg></Typography>
                        <Typography sx={{textAlign:"center"}}><strong>{thirdSection?.ts_title_2}</strong></Typography>
                        <Typography sx={{marginTop:"16px"}}>{thirdSection?.ts_content_2}</Typography>
                        </Box>
                        <Box className="">
                            <Typography style={{display: "flex", justifyContent: "center", alignItems: "center",paddingBottom:"10px"}}><svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M47.8334 24C47.8334 36.9787 37.3121 47.5 24.3334 47.5C11.3547 47.5 0.833374 36.9787 0.833374 24C0.833374 11.0213 11.3547 0.5 24.3334 0.5C37.3121 0.5 47.8334 11.0213 47.8334 24Z" stroke="white"></path>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3673 9H17.8673V9.5V19.1949V19.4835L18.1171 19.6278L23.557 22.7709C19.4931 23.2242 16.3334 26.6711 16.3334 30.8559C16.3334 35.3491 19.9758 38.9915 24.469 38.9915C28.9621 38.9915 32.6046 35.3491 32.6046 30.8559C32.6046 26.6711 29.4448 23.2242 25.3809 22.7709L30.8208 19.6278L31.0707 19.4835V19.1949V9.5V9H30.5707H18.3673ZM18.8673 18.9063V10H20.9181V20.0913L18.8673 18.9063ZM21.9181 20.6691V10H27.0198V20.6691L24.469 22.1429L21.9181 20.6691ZM28.0198 20.0913L30.0707 18.9063V10H28.0198V20.0913ZM31.6046 30.8559C31.6046 34.7968 28.4098 37.9915 24.469 37.9915C20.5281 37.9915 17.3334 34.7968 17.3334 30.8559C17.3334 26.9151 20.5281 23.7203 24.469 23.7203C28.4098 23.7203 31.6046 26.9151 31.6046 30.8559Z" fill="white"></path>
                            </svg></Typography>
                        <Typography sx={{textAlign:"center"}}><strong>{thirdSection?.ts_title_3}</strong></Typography>
                        <Typography sx={{marginTop:"16px"}}>{thirdSection?.ts_content_3}</Typography>
                        </Box>
                    </Box>
                </Box>
                {/* Table */}
                <Box sx={{marginTop:"24px"}} className="workListBox">
                    <Box className="haderRow" minWidth={"1135px"}>
                        <Box minWidth={"45%"} className="Tabelsel navFsel templatehead">
                            <Typography>NET-A-PORTER REWARDS TIER</Typography>
                        </Box>
                        <Box className="Tabelsel statusSel Tabelcentersel" minWidth={"15%"}>
                            <Typography>Silver</Typography>
                        </Box>
                        <Box className="Tabelsel statusSel Tabelcentersel" minWidth={"15%"}>
                            <Typography>Gold</Typography>
                        </Box>
                        <Box className="Tabelsel statusSel Tabelcentersel" minWidth={"16%"} >
                            <Typography>Platinum</Typography>
                        </Box>
                    </Box>
                    {futures.map((future) => {
                        return(
                            <Box className="TabelRow" minWidth={"1135px"}>
                                <Box minWidth={"45%"} className="Tabelsel navFsel navtable">                
                                    <Typography sx={{fontSize:"13px !important"}}> {future.title}</Typography>
                                </Box>
                                <Box className="Tabelsel statusSel Tabelcentersel" minWidth={"15%"} >
                                    {future.pearl == 'Yes' ?
                                        (
                                            <Box className="g lr ls lt lu lv">
                                                <Box class="z lw lx ly lz hf ma mb"></Box>
                                            </Box> 
                                        ) : (
                                            <Box></Box> 
                                        )
                                    }
                                </Box>
                                <Box className="Tabelsel statusSel Tabelcentersel" minWidth={"18%"} >                        
                                    {future.gold == 'Yes' ?
                                        (
                                            <Box class="g mc ls lt lu lv">
                                                <Box class="z lw lx ly lz hf ma mb"></Box>
                                            </Box> 
                                        ) : (
                                            <Box></Box> 
                                        )
                                    }
                                </Box>
                                <Box className="Tabelsel statusSel Tabelcentersel" minWidth={"15%"} >
                                    {future.diamond == 'Yes' ?
                                        (
                                            <Box class="g md ls lt lu lv">
                                                <Box class="z lw lx ly lz hf ma mb"></Box>
                                            </Box>
                                        ) : (
                                            <Box></Box> 
                                        )
                                    }
                                </Box>
                            </Box>
                        )
                    })}
                    </Box>
                {/* Table */}
                {/* Offer Page link */}
                <Box sx={{paddingBottom:"0px !important"}} className="RafatextBox">
                    <Typography className='rafaTBHText' style={{ textAlign : "center" }}>{fifthSection?.ffs_title}</Typography>
                    <Typography className='rafaTBNText' style={{ textAlign : "center" }}>{fifthSection?.ffs_content}</Typography>
                    <span className="discoverBtn" data-aos="fade" onClick={() => navigate(`${fifthSection?.ffs_button_link}`)}>
                    <Typography>{fifthSection?.ffs_button_label}</Typography>
                    <span className="DBLine"></span>
                    <span className="dbsLine dbTops"></span>
                    <span className="dbsLine dbRighs"></span>
                    <span className="dbsLine dbBottoms"></span>
                    <span className="dbsLine dbLefts"></span>
                    </span>
                </Box>
                {/* Offer Page link */}
                <Box className="RafatextBox" style={{marginBottom: '-160px'}}>
                    <Typography className='rafaTBHText' style={{ textAlign : "center" }}>{fourthSection?.fos_title}</Typography>
                    <Typography className='rafaTBNText'>{fourthSection?.fos_content}</Typography>
                    <Box className="ambCardBox">
                        <Box className="ambCard" style={{ width : "15%" }}>
                            <img src={`${BACKEND_URL}${fourthSection?.fos_image_1}`} />
                        </Box>
                        <Box className="ambCard" style={{ width : "15%" }}>
                            <img src={`${BACKEND_URL}${fourthSection?.fos_image_2}`} />
                        </Box>
                        <Box className="ambCard" style={{ width : "15%" }}>
                            <img src={`${BACKEND_URL}${fourthSection?.fos_image_3}`} />
                        </Box>
                    </Box>
                </Box>
                <EmailCommunity />
            </Box>
            <Footer />
        </>
    )
}
