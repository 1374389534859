import React, { useState, useRef, useEffect } from 'react';
import config from "../../config";
import './style.css';
import { Box, Typography } from '@mui/material';
import landingVideo from "../../Assets/Images/n25-landing-video.mp4"

const VideoWithPlayButton = ({ url }) => {
    const BACKEND_URL = config.BACKEND_URL;
    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(true);
    const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
    const [delayedPos, setDelayedPos] = useState({ x: 0, y: 0 });
    const videoRef = useRef(null);
    const timeoutRef = useRef(null);

    const handleMouseMove = (e) => {
        const rect = videoRef.current.getBoundingClientRect();
        setMousePos({
            x: e.clientX - rect.left,
            y: e.clientY - rect.top,
        });

        // Clear any existing timeout
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        // Set a timeout to update the delayed position
        timeoutRef.current = setTimeout(() => {
            setDelayedPos({
                x: e.clientX - rect.left,
                y: e.clientY - rect.top,
            });
        }, 1); // Adjust the delay here (in milliseconds)
    };

    const handlePlayPause = () => {
        const video = videoRef.current;
        if (isPlaying) {
            video.pause();
        } else {
            video.play();
        }
        setIsPlaying(!isPlaying);
    };

    const handleMuteVideo = () => {
        const video = videoRef.current;
        video.muted = !isMuted;
        setIsMuted(!isMuted);
    }

    return (


        <div className="videoContainer" onClick={handleMuteVideo} style={{ cursor: 'pointer' }} >
            <video ref={videoRef} className='foodVideoSection' autoPlay muted={isMuted} loop playsInline>
                <source src={landingVideo} />
            </video>
        </div>




        // <div className="videoContainer" onMouseMove={handleMouseMove} style={{ cursor: 'pointer' }} >
        //     <video ref={videoRef} className='foodVideoSection' autoPlay muted={isMuted} loop playsInline onClick={handleMuteVideo}>
        //         <source src={landingVideo} />
        //     </video>
        //     <Box className="vbuttonBox">
        //         {!isPlaying && (
        //             <button className="playButton" style={{ top: delayedPos.y, left: delayedPos.x }} onClick={handlePlayPause} >
        //                 ▶
        //             </button>
        //         )}
        //     </Box>
        // </div>
    );
};

export default VideoWithPlayButton;
